import React, {useEffect} from "react";
import AllProductContainer from "../../Components/PageContainers/AllProductContainer/AllProductContainer";
import {useDispatch, useSelector} from "react-redux";
import {fetchHomeData} from "../../Redux/Slices/rootSlice/rootSlice";
import {fetchAllBrand} from "../../Redux/Slices/Brand/brandSlice";
import Brand from "../Brand/Brand";


const AllProducts = () => {
    const dispatch = useDispatch();
    const {data} = useSelector((state) => state.home);
    const {brands} = useSelector((state) => state?.brands);

    useEffect(() => {
        dispatch(fetchHomeData());
        dispatch(fetchAllBrand());
    }, [dispatch]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);
    return (
        <div>
            <AllProductContainer flash={data?.flash_sale}/>
            <Brand brands={brands}/>
        </div>
)};

export default AllProducts;
