import React, {useEffect, useState} from "react";
import SectionHeading2 from "../SectionHeadings/SectionHeading2/SectionHeading2";
import {
    baseUrl,
    errorNotify,
    successNotify,
} from "../../Hooks/Helper";
import useStore from "../../Hooks/useStore";
import {ToastContainer} from "react-toastify";
import {
    fetchAllwishList,
    setUpdateWishlist,
} from "../../Redux/Slices/Wishlist/wishlistSlice";
import {useDispatch, useSelector} from "react-redux";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import AllProductLoader from "../Loaders/AllProductLoader/AllProductLoader";
import {BsCart3, BsShare} from "react-icons/bs";
import BreadCumb from "../BreadCumb/BreadCumb";
import Button from "../Button/Button";

const WishlistContainer = () => {
    const data = {
        heading: "Wishlist",
        paragraph: "Product of wishlist",
    };
    const dispatch = useDispatch();
    const {customerToken, update, setUpdate, customerInfo} = useStore().data;
    const {wishlist, updates, loading} = useSelector(
        (state) => state?.wishlist);
    const [selectedWishlist, setSelectedWishlist] = useState([]);
    const [visible, setVisible] = useState(false);
    const [wishListId, setWishListId] = useState();
    const [emailData, setEmailData] = useState({});
    const verified = customerInfo?.user_data?.email_verified_at;

    useEffect(() => {
        if (!customerToken || !verified) {
            return;
        }
        dispatch(fetchAllwishList({customerToken}));
    }, [dispatch, customerToken, updates, update, verified]);

    //WISHLIST DATA
    useEffect(() => {
        setSelectedWishlist(wishlist?.length > 0 && wishlist[0]);
    }, [wishlist]);

    const deleteWish = async (id) => {
        if (!customerToken?.length) {
            return
        }
        try {
            const res = await fetch(`${baseUrl.url}/user/wish-delete/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: `bearer ${customerToken}`,
                },
            });
            if (res.ok) {
                successNotify("wishlist deleted successfully!");
                setUpdate(!update);
                dispatch(setUpdateWishlist());
            }
        } catch (e) {
            console.error(e);
        }
    };

    const convertWishToCart = async (id) => {
        if (!customerToken?.length) {
            try {
                const res = await fetch(`${baseUrl.url}/user/convert-to-cart/${id}`, {
                    method: "GET",
                    headers: {
                        Authorization: `bearer ${customerToken}`,
                    },
                });
                if (res.ok) {
                    successNotify("Your wishlist products is added to cart!");
                    setUpdate(!update);
                    dispatch(setUpdateWishlist());
                }
            } catch (e) {
                console.error(e)
            }
        }
    };

    const changeText = (e) => {
        setEmailData({
            ...emailData,
            [e.target.name]: e.target.value,
        });
    };

    const shareWishList = async () => {
        if (!customerToken) {
            return
        }
        if (!emailData?.email) {
            return errorNotify("Email is required");
        }
        const postData = {
            email: emailData.email,
        };
        const res = await fetch(
            `${baseUrl.url}/user/wish-list-send/${wishListId}`,
            {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `bearer ${customerToken}`,
                },
                body: JSON.stringify(postData),
            }
        );
        const resData = await res.json();
        if (res.ok) {
            setUpdate(!update);
            setVisible(!visible);
            successNotify("Wishlist shared successfully");
        } else {
            resData.errors.map((err) => errorNotify(err));
        }

    };

    if (loading) {
        return <AllProductLoader/>;
    }

    const navigationData = [
        {
            page: "Home",
            route: "/",
        },
        {
            page: "Wishlist",
            route: "/wishlist",
        },
        {
            page: "Items",
            route: "#",
        },
    ];


    return (
        <section className="py-8 lg:py-16">
            <Rodal
                visible={visible}
                onClose={() => setVisible(!visible)}
                animation={"fade"}
                customStyles={{
                    minWidth: "400px",
                    height: "auto",
                    width: "25%",
                    bottom: "auto",
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
            >
                <div className="rodal-width p-2 text-slate-800">
                    <h2 className="text-base">Share Wishlist</h2>
                    <div>
                        <div className="mt-2">
                            <input
                                onChange={(e) => changeText(e)}
                                name="email"
                                className="w-full focus:outline-none bg-gray-200 p-2 rounded"
                                placeholder="Email Address"
                                type="email"
                            />
                            <Button size={"md"} classes={"mt-4"} func={shareWishList} title={"Submit"}/>
                        </div>
                    </div>
                </div>
            </Rodal>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="">
                    <SectionHeading2 data={data}/>
                    <BreadCumb data={navigationData}/>
                    <div>
                        <p className="text-3xl font-medium text-center text-gray-700">My Wishlist</p>
                    </div>
                    {wishlist?.length > 0 ? (
                        <div className="grid grid-cols-12 gap-3 mt-10  ">
                            <div className="col-span-12 lg:col-span-3 p-2 rounded border">
                                <h1 className="text-lg font-medium text-gray-700 dark:text-white">
                                    Name
                                </h1>
                                <div className="mt-3">
                                    {wishlist?.map((wish, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={`w-full mb-2 hover:bg-gray-200 hover:dark:bg-gray-600 p-2 group ${selectedWishlist?.id === wish?.id && "bg-gray-200 dark:bg-gray-600"}`}
                                            >
                                                <div
                                                    className={`text-left dark:text-white flex gap-1 items-center justify-between cursor-pointer `}
                                                    onClick={() => {
                                                        setSelectedWishlist(wish);
                                                    }}
                                                >
                                                    <div className="">
                                                        <button

                                                        >
                                                            {i + 1} . {wish?.title}
                                                            <span
                                                                className="ml-3 text-xs text-gray-500 dark:text-white">
                                                                    {wish?.items?.length} items
                                                            </span>
                                                        </button>

                                                    </div>
                                                    <span
                                                        className={`group-hover:opacity-100 cursor-pointer text-xs text-red-600 ml-1 ${selectedWishlist?.id === wish?.id ? "opacity-100" : "opacity-0"}`}
                                                        onClick={() => deleteWish(wish?.id)}
                                                    >
                                                         Remove
                                                     </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="col-span-12 lg:col-span-9  p-2 rounded border">
                                <div className="flex justify-between items-center py-2">
                                    <h1 className="text-lg font-medium text-gray-700 dark:text-white">
                                        Items
                                    </h1>

                                    <div className="my-1 flex gap-4">
                                        <button
                                            className="flex gap-1 items-center text-red-400 text-sm font-medium"
                                            onClick={() => convertWishToCart(selectedWishlist?.id)}
                                        >
                                            <BsCart3/>
                                            Add to Cart
                                        </button>
                                        <button
                                            className="flex gap-1 items-center text-sky-600 text-sm font-medium"
                                            onClick={() => {
                                                setVisible(!visible);
                                                setWishListId(selectedWishlist?.id);
                                            }}
                                        >
                                            <BsShare/> Share
                                        </button>

                                    </div>

                                </div>

                                <div className="mt-3 overflow-auto">

                                    {selectedWishlist?.items?.length > 0 && (
                                        <table className="table-auto w-full ">
                                            <thead className="w-full mb-3">
                                            <tr>
                                                <th className="text-left">
                                                    <span className="text-sm lg:text-base dark:text-white text-center">
                                                      {" "}
                                                        Product
                                                    </span>
                                                </th>

                                                <th className="text-left">
                                                    <span className="text-sm lg:text-base dark:text-white text-center">
                                                      {" "}
                                                        Price
                                                    </span>
                                                </th>
                                                <th className="lg:text-left">
                                                    <span className="text-sm lg:text-base dark:text-white text-center">
                                                      {" "}
                                                        Status
                                                    </span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="w-full mt-5">
                                            {selectedWishlist?.items?.map((item, i) => {
                                                return (
                                                    <tr key={i} className="w-full">
                                                        <td className="text-sm text-slate-700 pl-2 flex dark:text-white text-center whitespace-nowrap">
                                                            <img
                                                                className="w-12 h-12 rounded bg-slate-100 text-center"
                                                                src={`${baseUrl.img}${item?.product_combination?.product?.thumbnail_image}`}
                                                                alt="wish"
                                                            />
                                                            <div className="ml-2">
                                                                <p className="lg:text-base text-sm text-left">
                                                                    {item?.product_combination?.product?.name?.slice(
                                                                        0,
                                                                        55
                                                                    )}
                                                                </p>
                                                                {item?.product_combination?.attribute_values.map(
                                                                    (attVal, j) => attVal?.attribute?.name !== "default" &&
                                                                        <p key={j}
                                                                           className="text-left text-xs font-medium">{attVal?.attribute?.name}: {attVal.name}</p>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <th className="text-left">
                                                            <span
                                                                className="text-sm lg:text-base dark:text-white text-center">
                                                              {" "}
                                                                {item?.product_combination?.selling_price}
                                                            </span>
                                                        </th>
                                                        <th className="text-left">
                                                            <span
                                                                className="text-sm lg:text-base dark:text-white text-left">
                                                              {" "}
                                                                {item?.product_combination?.inventory_sum_stock_quantity > 0 ? (
                                                                    <p className="text-green-500 font-bold">
                                                                        In Stock
                                                                    </p>
                                                                ) : (
                                                                    <p className="text-red-500 font-bold">
                                                                        out of Stock
                                                                    </p>
                                                                )}
                                                            </span>
                                                        </th>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="md:min-h-[300px] md:flex md:items-center md:justify-center">
                            <p className="dark:text-white flex items-center justify-center">
                                There is no saved item yet!
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WishlistContainer;