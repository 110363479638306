import React from "react";
import {baseUrl, footerMenu} from "../../../Hooks/Helper";
import {Link, useNavigate} from "react-router-dom";
import useStore from "../../../Hooks/useStore";
import {GrLinkedinOption} from "react-icons/gr";
import {BsInstagram, BsPinterest} from "react-icons/bs";
import {AiOutlineYoutube} from "react-icons/ai";
import {FiTwitter} from "react-icons/fi";
import {FaFacebookF} from "react-icons/fa";
import {MdOutlineHeadsetMic} from "react-icons/md";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";

const Footer6 = ({staticMenus}) => {
    const {setting, darkMode} = useStore().data;
    const navigate = useNavigate();
    const {
        facebook,
        instagram,
        twitter,
        youtube,
        pinterest,
        linkedin,
        name,
        logo,
        address,
        email,
        dark_logo,
        phone,
    } = setting;
    const newTabWithLink = (link) => {
        window.open(link);
    };
    return (
        <footer className="dark:bg-gray-800">
            <SectionWrapper last={true}>
                <div>
                    <div className="grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-4 ">
                        <div>
                            <div className="border-b border-b-gray-300">
                                <Link to={"/"} className="">
                                    {dark_logo || logo ? (
                                        <img
                                            className={"w-44 lg:w-40"}
                                            src={
                                                darkMode
                                                    ? `${baseUrl.img}${dark_logo}`
                                                    : `${baseUrl.img}${logo}`
                                            }
                                            alt={"website logo"}
                                        />
                                    ) : (
                                        <img
                                            className={"w-44 lg:w-40"}
                                            src="https://placehold.co/160x34"
                                            alt={"website logo"}
                                        />
                                    )}
                                </Link>
                                <p className="text-xs  lg:pb-2 mt-2 dark:text-white">{address}</p>
                            </div>
                            <div
                                className="flex items-center justify-start gap-2 border-b border-b-gray-300 py-4 dark:text-white">
                                <MdOutlineHeadsetMic size={50}/>
                                <div>
                                    <div className="flex justify-between">
                                        <p className="text-base flex items-center">Email:</p>
                                        <span className="flex items-center justify-center lg:text-sm text-xs">
                                        {email}
                                      </span>
                                    </div>
                                    <div className="flex justify-between">
                                        <p className="text-base flex items-center">Call:</p>
                                        <span className="flex items-center justify-center text-sm">
                                            {phone}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-1 cursor-pointer border-b border-b-gray-300 py-4 mb-4">

                                {
                                    facebook &&
                                    <div
                                        className="bg-red-400 text-white w-6 h-6 flex items-center justify-center hover:bg-gray-400 transition ease-in delay-150 rounded-full">
                                        <FaFacebookF
                                            size={15}
                                            onClick={() => newTabWithLink(facebook)}
                                            className=""
                                        />
                                    </div>
                                }

                                {
                                    instagram &&
                                    <div
                                        className="bg-red-400 text-white w-6 h-6 flex items-center justify-center hover:bg-gray-400 transition ease-in delay-150 rounded-full">
                                        <BsInstagram
                                            size={15}
                                            onClick={() => newTabWithLink(instagram)}
                                            className=""
                                        />
                                    </div>
                                }
                                {
                                    twitter &&

                                    <div
                                        className="bg-red-400 text-white w-6 h-6 flex items-center justify-center hover:bg-gray-400 transition ease-in delay-150 rounded-full">
                                        <FiTwitter
                                            size={15}
                                            onClick={() => newTabWithLink(twitter)}
                                            className=""
                                        />
                                    </div>

                                }
                                {
                                    youtube &&
                                    <div
                                        className="bg-red-400 text-white w-6 h-6 flex items-center justify-center hover:bg-gray-400 transition ease-in delay-150 rounded-full">
                                        <AiOutlineYoutube
                                            size={15}
                                            onClick={() => newTabWithLink(youtube)}
                                            className=""
                                        />
                                    </div>
                                }
                                {
                                    linkedin &&
                                    <div
                                        className="bg-red-400 text-white  w-6 h-6 flex items-center justify-center hover:bg-gray-400 transition ease-in delay-150 rounded-full">
                                        <GrLinkedinOption
                                            size={15}
                                            onClick={() => newTabWithLink(linkedin)}
                                            className=""
                                        />
                                    </div>
                                }
                                {
                                    pinterest &&
                                    <div
                                        className="bg-red-400 text-white w-6 h-6 flex items-center justify-center hover:bg-gray-400 transition ease-in delay-150 rounded-full">
                                        <BsPinterest
                                            size={15}
                                            onClick={() => newTabWithLink(pinterest)}
                                            className=""
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                        {footerMenu?.map((menu, i) => (
                            <div key={i}>
                                <h5 className="text-lg font-medium  dark:text-white">
                                    {menu.heading}
                                </h5>
                                <ul>
                                    {menu.links.map((item, i) => (
                                        <li key={i} className="hover:pl-2 duration-300 cursor-pointer">
                                            <Link className="text-sm dark:text-white " to={item.route}>
                                                {item.linkName}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className="flex-wrap lg:flex items-center justify-center ">
                        <div className="">
                            <ul className="flex gap-2 ">
                                {staticMenus?.map((item, i) => {
                                    if (item.static_menu_type.name === "Header") {
                                        return null;
                                    }
                                    return (
                                        <li key={i} className="">
                                            <button
                                                className={`border-0`}
                                                onClick={() => {
                                                    navigate(`/page/${item.id}`);
                                                }}
                                            >
                                              <span className="  text-sm dark:text-white  ">
                                                {item.menu_name}
                                              </span>
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>

                        </div>
                        {/*<div className="flex gap-1 mt-3 lg:mt-0">*/}
                        {/*    <div className="h-12 w-full">*/}
                        {/*        <img*/}
                        {/*            src={require("./162-48-01.png")}*/}
                        {/*            className="w-auto h-full "*/}
                        {/*            alt="payment"*/}
                        {/*        />*/}
                        {/*    </div>*/}

                        {/*    <div className="h-12 w-full">*/}
                        {/*        <img*/}
                        {/*            src={require("./162-48-02.png")}*/}
                        {/*            className="w-auto h-full "*/}
                        {/*            alt="payment"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className="flex items-center justify-center py-4">
                        <p className="md:text-sm text-xs dark:text-white">
                            Copyright &copy;2023 <span className="">{name}</span>.All Rights
                            Reservered.Powered by <span className="text-red-400">Selopia</span>.
                        </p>
                    </div>
                </div>
            </SectionWrapper>
        </footer>
    );
};

export default Footer6;
