import {useEffect, useState} from "react";
import {baseUrl, errorNotify, getFromCookie} from "./Helper";
import errImg from "../assets/product-default-img/dummy.png";
import bannerImg from "../assets/product-default-img/banner3.png";
// added
const useData = () => {
    const [pageName, setPageName] = useState("");
    const [compare, setCompare] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    //token
    const [customerToken, setCustomerToken] = useState("");
    const [secondaryToken, setSecondaryToken] = useState("");
    const [refreshAll, setRefreshAll] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [productDetails, setProductDetails] = useState();
    const [darkMode, setDarkMode] = useState(false);
    // address states
    const [countries, setCountries] = useState([]);
    const [countryId, setCountryId] = useState(null);
    const [divisions, setDivisions] = useState([]);
    const [divisionId, setDivisionId] = useState(null);
    const [districts, setDistricts] = useState([]);
    const [districtId, setDistrictId] = useState(null);
    const [subDistricts, setSubDistricts] = useState([]);
    const [subDisId, setSubDisId] = useState(null);
    // const [unions, setUnions] = useState([]);
    const [setting, setSetting] = useState({});


    const [customerInfo, setCustomerInfo] = useState({});
    const [update, setUpdate] = useState(false);

    const selectedDivisions = divisions?.filter(
        (div) => +div.country_id === +countryId
    );
    const selectedDistricts = districts?.filter(
        (dis) => +dis.division_id === +divisionId
    );
    const selectedSubDis = subDistricts?.filter(
        (subDis) => +(subDis.district_id === +districtId)
    );

    // const selectedUnions = unions?.filter((uni) => +uni.upazila_id === +subDisId);

    // filter products state
    const [filteredProducts, setFilteredProducts] = useState();
    const [categoryFilteredProducts, setCategoryFilteredProducts] = useState([]);
    const [viewAllFlashSell, setViewAllFlashSell] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [featureCat, setFeatureCat] = useState(0);
    const [categoryId, setCategoryId] = useState(null);
    const [subCategoryId, setSubCategoryId] = useState("");
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [productName, setProductName] = useState("");
    // product details page
    const [selectedIds, setSelectedIds] = useState([]);
    const [flashSale, SetFlashSale] = useState({});
    //compare
    const [compareProducts, setCompareProducts] = useState([]);
    // products
    const [productReviews, setProductReviews] = useState([]);

    // Branch
    const [isBranch, setIsBranch] = useState("")
    const [branchList, setBranchList] = useState([])
    const [selectedBranch, setSelectedBranch] = useState({});
    const [branchOptions, setBranchOptions] = useState([]);

    // error image function
    const showDummyImage = ({currentTarget}) => (currentTarget.src = errImg);
    const showDummyBanner = ({currentTarget}) =>
        (currentTarget.src = bannerImg);

    // frontend configuration
    // useEffect(() => {
    //     const fav = document.getElementById("favIcon");
    //     document.title = setting?.name ? setting?.name : "Selopia";
    //     fav.href = `${baseUrl.img}${setting?.favicon}`;
    // }, [setting?.favicon, setting?.name]);


    useEffect(() => {
        if (!baseUrl.token) {
            return;
        }
        const getSettings = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/general-setting`, {
                    method: "GET",
                    headers: {
                        Authorization: baseUrl.token,
                    },
                });
                const resData = await res.json();
                if (res.ok) {
                    setIsBranch(resData?.data?.is_admin_select_branch)
                    setSetting(resData?.data);
                }
            } catch (e) {

            }
        };
        getSettings().then();
    }, []);

    // branch
    useEffect(() => {
        if (!baseUrl.token && setting?.is_admin_select_branch === 1) {
            return;
        }
        const getBranch = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/shop-branches-all`, {
                    method: "GET",
                    headers: {
                        Authorization: baseUrl.token,
                    },
                });
                const resData = await res.json();
                if (res.ok) {
                    setBranchList(resData?.data);

                    const modifyData = resData?.data?.map(data => {
                        const {id, name} = data
                        return {
                            label: name, value: id
                        }
                    })
                    setBranchOptions(modifyData);
                }
            } catch (e) {
                console.error("error",e)
            }
        };
        getBranch().then();
    }, [setting?.is_admin_select_branch]);

    // address url fetch start-------------------------------------------------------------------------------
    useEffect(() => {
        if (!baseUrl.token) {
            return;
        }
        const getCountry = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/asset/country-list`, {
                    method: "GET",
                    headers: {
                        Authorization: baseUrl.token,
                    },
                });
                const countryData = await res.json();
                if (res.status === 200) {
                    setCountries(countryData.data);
                }
            } catch (e) {

            }
        };
        getCountry().then();
    }, [pageName]);


    useEffect(() => {
        if (!baseUrl.token) {
            return;
        }
        if (!countryId) {
            return;
        }
        const getDivision = async () => {
            try {
                const res = await fetch(
                    `${baseUrl.url}/asset/division-list?country_id=${countryId}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: baseUrl.token,
                        },
                    }
                );
                const divisionData = await res.json();
                if (res.status === 200) {
                    setDivisions(divisionData.data);
                }
            } catch (e) {

            }
        };
        getDivision().then();
    }, [countryId]);

    useEffect(() => {
        setDistricts([]);
        if (!baseUrl.token) {
            return;
        }
        if ( !divisionId) {
            return;
        }
        const getDistrict = async () => {
            try {
                const res = await fetch(
                    `${baseUrl.url}/asset/district-list?division_id=${divisionId}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: baseUrl.token,
                        },
                    }
                );
                const districtData = await res.json();
                if (res.status === 200) {
                    setDistricts(districtData.data);
                }
            } catch (e) {

            }
        };
        getDistrict().then();
    }, [pageName, divisionId]);


    useEffect(() => {
        setSubDistricts([]);
        if (!baseUrl.token) {
            return;
        }
        if (!districtId) {
            return
        }

        const getSubDistrict = async () => {
            try {
                const res = await fetch(
                    `${baseUrl.url}/asset/sub-district-list?district_id=${districtId}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: baseUrl.token,
                        },
                    }
                );
                if (res.status === 200) {
                    const subDistrictData = await res.json();
                    setSubDistricts(subDistrictData.data);
                }
            } catch (e) {

            }
        };
        getSubDistrict().then();
    }, [pageName, districtId, divisionId]);

    // useEffect(() => {
    //   setUnions([]);
    //   if (!baseUrl.token) {
    //     return;
    //   }
    //   if(!subDisId){
    //     return
    //   }
    //   if (!pageName || pageName !== "address") {
    //     return;
    //   }
    //   const getUnion = async () => {
    //     const res = await fetch(
    //       `${baseUrl.url}/asset/union-list?sub_district_id=${subDisId}`,
    //       {
    //         method: "GET",
    //         headers: {
    //           Authorization: baseUrl.token,
    //         },
    //       }
    //     );
    //     const unionData = await res.json();
    //     if (res.status === 200) {
    //       setUnions(unionData.data);
    //     }
    //   };
    //   getUnion();
    // }, [pageName, subDisId]);

    // address url fetch end---------------------------------------------------------------------------------

    // user address details
    const [updateAddress, setUpdateAddress] = useState(false);
    useEffect(() => {
        if (!customerToken) {
            return;
        }
        const getUserDetails = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/user/address-list`, {
                    headers: {
                        Authorization: `bearer ${customerToken}`,
                    },
                });
                if (res.status === 200) {
                    const userData = await res.json();
                    setAddresses(userData.addresses);
                } else {
                    setAddresses([]);
                }
            }
            catch (e) {
                console.error(e)
            }
        };
        getUserDetails().then();
    }, [customerToken, updateAddress]);

    const [userLoader, setUserLoader] = useState(true);

    useEffect(() => {
        const isLogin = getFromCookie("isLogin")
        if (isLogin !== "1") {
            setUserLoader(false)
            return
        }
        const handleRefresh = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/user/refresh`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "content-type": "application/json",
                    }
                });
                if (res.ok) {
                    const resData = await res.json();
                    setCustomerToken(resData?.customer_access_token);
                }
            } catch (error) {
                console.error("error",error)
            } finally {
                setUserLoader(false);
            }
        };
        if (!customerToken && isLogin === "1") {
            handleRefresh().then();
        }
        const interval = setInterval(handleRefresh, 14 * 60 * 1000);
        return () => clearInterval(interval);
    }, [customerToken, setCustomerToken]);


    useEffect(() => {
        if (!customerToken) {
            return;
        }
        const getCustomerInfo = async () => {
            try {
                const res = await fetch(`${baseUrl.url}/user/me`, {
                    method: "GET",
                    headers: {
                        Authorization: `bearer ${customerToken}`,
                    },
                });
                const resData = await res.json();
                if (res.ok) {
                    setCustomerInfo(resData.data);
                }
            } catch (error) {
                errorNotify(error)
            }
        }

        getCustomerInfo().then();
    }, [customerToken, update, setUpdate]);

    return {
        customerInfo,
        userLoader,
        setUserLoader,
        secondaryToken,
        setCustomerToken,
        customerToken,
        setUpdate,
        update,
        addresses,
        setAddresses,
        countries,
        setCountryId,
        divisionId,
        setDivisionId,
        districtId,
        setDistrictId,
        setSubDisId,
        subDisId,
        // selectedUnions,
        selectedDivisions,
        selectedDistricts,
        selectedSubDis,
        setting,
        filteredProducts,
        setFilteredProducts,
        categoryFilteredProducts,
        setCategoryFilteredProducts,
        userInfo,
        setUserInfo,
        showDummyImage,
        viewAllFlashSell,
        setViewAllFlashSell,
        featureCat,
        setFeatureCat,
        discount,
        setDiscount,
        categoryId,
        setCategoryId,
        subCategoryId,
        setSubCategoryId,
        setPageName,
        productReviews,
        setProductReviews,
        refreshAll,
        setRefreshAll,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        productName,
        setProductName,
        updateAddress,
        setUpdateAddress,
        divisions,
        districts,
        subDistricts,
        // unions,
        showDummyBanner,
        compareProducts,
        setCompareProducts,
        productDetails,
        setProductDetails,
        selectedIds,
        setSelectedIds,
        flashSale,
        SetFlashSale,
        compare,
        setSecondaryToken,
        setCompare,
        darkMode,
        setDarkMode,
        isBranch,
        setIsBranch,
        branchList,
        setBranchList,
        selectedBranch,
        setSelectedBranch,
        branchOptions,
        setBranchOptions
    };
};

export default useData;
