import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl, errorNotify, successNotify } from "../../../../Hooks/Helper";
import useStore from "../../../../Hooks/useStore";
import Button from "../../../Button/Button";
const UpdateInfo = () => {
  const { customerToken, customerInfo, update, setUpdate } = useStore().data
  const [data, setData] = useState({});


  const changeText = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };


  const updateInfo = async (e) => {
    e.preventDefault();
    const postData = {
      name: data?.name?.length > 0 ? data?.name : customerInfo?.user_data?.name,
      username: data?.email?.length > 0 ? data?.email : customerInfo?.user_data?.username,
      phone: data?.phone?.length > 0 ? data?.phone : customerInfo?.user_data?.phone,
      gender: data?.gender ? data?.gender : customerInfo?.user_data?.profile?.gender?.id,
    };
    const res = await fetch(`${baseUrl.url}/user/profile-update`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `bearer ${customerToken}`,
      },
      body: JSON.stringify(postData),
    });
    const resData = await res.json();
    if (res.ok) {
      successNotify("Profile updated successfully");
      setUpdate(!update);
    } else {
      errorNotify(resData.message);
    }
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex items-start justify-between mb-3">
        <div className="account-information-section">
        <h2 className="text-lg text-slate-500 dark:text-white tracking-wider">
          Account update
        </h2>
        </div>
      </div>
      <form onSubmit={updateInfo}>
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-6">
            <div className="mb-3">
              <label htmlFor="userName block" className="dark:text-white">User name</label>
              <input
                type="text"
                name="name"
                onChange={(e) => changeText(e)}
                defaultValue={customerInfo?.user_data?.name}
                className="border w-full primary-text-color form-control bg-transparent focus:outline-none focus:border-blue-300 p-2 rounded-sm dark:text-white"
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="mb-3">
              <label htmlFor="userName block" className="dark:text-white">User email</label>
              <input
                type="email"
                name="email"
                onChange={(e) => changeText(e)}
                defaultValue={customerInfo?.user_data?.username}
                className="border w-full rounded-sm p-2 focus:outline-none focus:border-blue-300 primary-text-color form-control bg-transparent dark:text-white"
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="mb-3">
              <label htmlFor="userName block" className="dark:text-white">Phone number</label>
              <input
                type="number"
                name="phone"
                onChange={(e) => changeText(e)}
                defaultValue={customerInfo?.user_data?.phone}
                className="border w-full rounded-sm p-2 focus:outline-none focus:border-blue-300 primary-text-color form-control bg-transparent dark:text-white"
              />
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="mb-3">
              <label htmlFor="userName block" className="dark:text-white">Gender</label>
              <select
                name="gender"
                className="border w-full rounded-sm p-2 focus:outline-none focus:border-blue-300 primary-text-color form-control bg-grey2 product-container-color dark:bg-gray-800 dark:text-white"
                onChange={(e) => changeText(e)}
              >
                <option defaultValue={customerInfo?.user_data?.profile?.gender?.name} className="dark:bg-gray-800 dark:text-white">{customerInfo?.user_data?.profile?.gender?.name}</option>
                <option Value={2} className="dark:bg-gray-800 dark:text-white">Female</option>
                <option Value={1} className="dark:bg-gray-800 dark:text-white">Male</option>
              </select>
            </div>
          </div>
        </div>
        <Button submit={true} size={"md"} title={"Update Info"}/>
      </form>
    </div>
  );
};

export default UpdateInfo;