import React, {useEffect, useState} from 'react';
import Select from "react-select";
import {GoArrowLeft, GoArrowRight} from "react-icons/go";
import Button from "../Button/Button";
import GoogleMap from "../GoogleMap/GoogleMap";
import Rodal from "rodal";
import {baseUrl, errorNotify, successNotify} from "../../Hooks/Helper";
import useStore from "../../Hooks/useStore";
import {useDispatch, useSelector} from "react-redux";
import {getPostalCodes} from "../../Redux/Slices/Location/postalCodeSlice";
import {getAreas} from "../../Redux/Slices/Location/areaSlice";

const AddressSave = ({visibleModal} ) => {
    const  {showAddModal, setShowAddModal} = visibleModal;
    const dispatch = useDispatch()
    const {
        customerToken,
        countries,
        divisions,
        setDivisionId,
        setCountryId,
        setDistrictId,
        districts,
        subDistricts,
        subDisId,
        update,
        setUpdate,
        setSubDisId,
        setting
    } = useStore().data;

    const [addData, setAddData] = useState({});
    const [position, setPosition] = useState(null);
    const innerWidth = window?.innerWidth;

    // modify option
    const [divisionOptions, setDivisionOptions] = useState([]);
    const [districtOption, setDistrictOption] = useState([]);
    const [subDistrictOption, setSubDistrictOption] = useState([]);

    // selected option value
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedDivision, setSelectedDivision] = useState("");
    const [selectedDistrict, setSelectedDistrict] = useState("")
    const [selectedSubDistrict, setSelectedSubDistrict] = useState("")
    const [selectedPostalCode, setSelectedPostalCode] = useState("")
    const [selectedArea, setSelectedArea] = useState("")
    const [showMap, setShowMap] = useState(false)

    const postalCodes = useSelector((state) => state.postalCode.postalCodes)
    const areas = useSelector((state) => state.area.area)



    useEffect(() => {
        if (selectedDistrict && selectedSubDistrict) {
            const data = {
                city: selectedDistrict?.label,
                thana: selectedSubDistrict?.label
            }
            dispatch(getPostalCodes(data))

            if (selectedPostalCode) {
                const data = {
                    postcode: selectedPostalCode?.value
                }
                dispatch(getAreas(data))
            }
        }

    }, [dispatch, selectedDistrict, selectedSubDistrict, selectedPostalCode])

    const handleInputChange2 = (event) => {
        const {name, value} = event.target;
        setAddData((prevData) => ({...prevData, [name]: value}));
    };


    useEffect(() => {
        const modifyOption = divisions?.map((user) => {
            const {name, id} = user;
            return {value: id, label: name};

        });
        setDivisionOptions(modifyOption);
    }, [divisions]);

    // modify district
    useEffect(() => {
        const modifyOption = districts?.map((district) => {
            const {name, id} = district;
            return {value: id, label: name};

        });
        setDistrictOption(modifyOption);
    }, [districts]);

    // modify upazila/ sub district
    useEffect(() => {
        const modifyOption = subDistricts?.map((subDistrict) => {
            const {name, id} = subDistrict;
            return {value: id, label: name};

        });
        setSubDistrictOption(modifyOption);
    }, [subDistricts]);



    const addAddress = async (e) => {
        e.preventDefault();
        const phoneNumberRegex = /^\+880(1\d{9}|2\d{8}|3\d{8})$/;
        let updatedPhoneNumber = addData?.phone_no?.startsWith("+88") ? addData?.phone_no : `+88${addData?.phone_no}`
        if (!phoneNumberRegex.test(updatedPhoneNumber)) {
            errorNotify("The phone number format is invalid")
            return;
        }
        const postData = {
            upazila_id: +setting?.default_delivery_system !== 3 ? subDisId : "",
            postal_code: +setting?.default_delivery_system !== 3 ? selectedPostalCode?.value : " ",
            area: +setting?.default_delivery_system !== 3 ? selectedArea?.value : "",
            phone_no: updatedPhoneNumber,
            address: addData?.address,
        }

        if (+setting?.default_delivery_system !== 3 && !postData?.upazila_id) {
            errorNotify("Please select your upazila")
            return
        }
        if (+setting?.default_delivery_system !== 3 && !postData?.postal_code) {
            errorNotify("Postal code is required")
            return
        }
        if (!postData?.address) {
            errorNotify("Address is required")
            return
        }
        postData.is_default = 0;
        const formData = new FormData();
        for (const key in postData) {
            const element = postData[key];
            formData.append(`${key}`, element);
        }

        if (Number(setting?.default_delivery_system === 3) && !position) {
            return errorNotify("Position field is required")
        }

        formData.append("lat", +setting?.default_delivery_system === 3 ? position?.lat : "")
        formData.append("lng", +setting?.default_delivery_system === 3 ? position?.lng : "")

        const res = await fetch(`${baseUrl.url}/user/create-new-address`, {
            method: "POST",
            headers: {
                Authorization: `bearer ${customerToken}`,
            },
            body: formData,
        });
        const resData = await res.json();
        if (res.ok) {
            setUpdate(!update)
            // setUpdateChange(!updateChange)
            setShowAddModal(false);
            setAddData({
                address: "",
                phone_no: ""

            })
            setSelectedCountry(null)
            setCountryId("")
            setSelectedDivision(null)
            setDistrictId("")
            setSelectedDistrict(null)
            setDistrictId("")
            setSelectedSubDistrict(null)
            setSubDisId(null)
            setSelectedPostalCode(null)
            setSelectedArea(null)
            successNotify("successfully address created");
        } else {
            errorNotify(resData?.errors[0])
        }
    };

    return (
        <div>
            <Rodal
                visible={showAddModal}
                enterAnimation="flip"
                closeMaskOnClick={false}
                animation="slideRight"
                onClose={() => {
                    setShowAddModal(!showAddModal)
                    setAddData({
                        address: "",
                        phone_no: ""
                    })
                    setSelectedCountry(null)
                    setCountryId("")
                    setSelectedDivision(null)
                    setDistrictId("")
                    setSelectedDistrict(null)
                    setDistrictId("")
                    setSelectedSubDistrict(null)
                    setSubDisId(null)
                    setSelectedPostalCode(null)
                    setSelectedArea(null)
                }}
                customStyles={{
                    height: "100%",
                    width: innerWidth > 1024 ? "40%" : "90%",
                    bottom: "auto",
                    top: "1%",
                    transform: "translateY(-50%)",
                    overflow: "scroll",
                }}
            >
                <div className="overflow-y-scroll scrollbar-hidden ">
                    <p className="text-xl border-b py-3 text-black">Create New Address</p>
                    <div className="py-3 " >
                        <div className={`grid grid-cols-12 gap-3 ${showMap && "hidden"}`}>

                            {
                                +setting.default_delivery_system !== 3 &&
                                <div className="col-span-12 lg:col-span-6">
                                    <label className="font-semibold text-gray-800" htmlFor="">
                                        Country
                                    </label>
                                    <Select
                                        isClearable
                                        getOptionLabel={({name}) => name}
                                        value={selectedCountry}
                                        options={countries}
                                        placeholder="Select Country"
                                        onChange={(e) => {
                                            setCountryId(e?.id)
                                            setSelectedCountry(e)
                                            setSelectedDivision("")
                                            setSelectedDistrict("")
                                            setDistrictId("")
                                            setSubDisId("");
                                            setSelectedSubDistrict("")
                                            setSelectedPostalCode("")
                                            setSelectedArea("")
                                        }}
                                    />
                                </div>
                            }
                            {
                                +setting.default_delivery_system !== 3 &&
                                <div className="col-span-12 lg:col-span-6 w-full">
                                    <label className="font-semibold text-gray-800" htmlFor="">
                                        Division
                                    </label>
                                    <Select
                                        isClearable
                                        options={divisionOptions}
                                        value={selectedDivision}
                                        // isDisabled={!countryId}
                                        placeholder="Select division"
                                        onChange={(e) => {
                                            setSelectedDivision(e)
                                            setDivisionId(e?.value)
                                            setSelectedDistrict("")
                                            setDistrictId("")
                                            setSubDisId("");
                                            setSelectedSubDistrict("")
                                            setSelectedPostalCode("")
                                            setSelectedArea("")
                                        }}
                                    />
                                </div>

                            }

                            {
                                +setting.default_delivery_system !== 3 &&
                                <div className="col-span-12 lg:col-span-6">
                                    <label className="font-semibold text-gray-800" htmlFor="">
                                        District
                                    </label>
                                    <Select
                                        isClearable
                                        // isDisabled={!divisionId?.length}
                                        options={districtOption}
                                        placeholder="Select district"
                                        value={selectedDistrict}
                                        onChange={(e) => {
                                            setSelectedDistrict(e);
                                            setDistrictId(e?.value);
                                            setSelectedSubDistrict("")
                                            setSelectedPostalCode("")
                                            setSelectedArea("")
                                        }}
                                    />
                                </div>
                            }

                            {
                                +setting.default_delivery_system !== 3 &&
                                <div className="col-span-12 lg:col-span-6">
                                    <label className="font-semibold text-gray-800" htmlFor="">
                                        Upazila
                                    </label>
                                    <Select
                                        isClearable
                                        // isDisabled={true}
                                        options={subDistrictOption}
                                        value={selectedSubDistrict}
                                        placeholder="Select Upazila"
                                        onChange={(e) => {
                                            setSelectedSubDistrict(e)
                                            setSubDisId(e?.value)
                                            setSelectedPostalCode("")
                                            setSelectedArea("")
                                        }}
                                    />
                                </div>

                            }

                            {
                                +setting?.default_delivery_system !== 3 &&
                                <div className="col-span-12 lg:col-span-6">
                                    <label className="font-semibold text-gray-800" htmlFor="">
                                        Postal/Zip code
                                    </label>

                                    <Select
                                        isClearable
                                        getOptionLabel={({name}) => name}
                                        value={selectedPostalCode}
                                        options={postalCodes}
                                        placeholder="Select Postal/Zip code"
                                        onChange={(e) => {
                                            setSelectedPostalCode(e)
                                            setSelectedArea("")
                                        }}
                                    />
                                </div>
                            }

                            {
                                +setting.default_delivery_system !== 3 &&

                                <div className="col-span-12 lg:col-span-6">
                                    <label className="font-semibold text-gray-800" htmlFor="">
                                        Area
                                    </label>
                                    <Select
                                        isClearable
                                        getOptionLabel={({name}) => name}
                                        // isDisabled={true}
                                        options={areas}
                                        value={selectedArea}
                                        placeholder="Select area"
                                        onChange={(e) => {
                                            setSelectedArea(e)
                                        }}
                                    />
                                </div>

                            }

                            <div className="col-span-12 lg:col-span-6">
                                <label className="font-semibold text-gray-800 block">
                                    Phone number
                                </label>
                                <div className="flex">
                                    <span>+88</span>
                                    <input
                                        name="phone_no"
                                        value={addData?.phone_no}
                                        onChange={handleInputChange2}
                                        type="tel"
                                        className="w-full border-0 border-b border-gray-500 rounded-0  bg-transparent text-black focus:outline-none"
                                    />
                                </div>
                            </div>
                            <div className="col-span-12">
                                <label className="font-semibold text-gray-800" htmlFor="">
                                    Address
                                </label>
                                <textarea
                                    name="address"
                                    value={addData?.address}
                                    onChange={handleInputChange2}
                                    className="w-full text-black border-gray-500 form-control border p-5 rounded  bg-transparent focus:outline-none"
                                />
                            </div>

                            {
                                +setting.default_delivery_system === 3 &&
                                <div className="flex justify-end col-span-12">
                                    <span className="flex items-center gap-1 text-red-400 cursor-pointer"
                                          onClick={() => setShowMap(true)}>Next <GoArrowRight size="21"/></span>
                                </div>
                            }
                        </div>

                        {
                            +setting.default_delivery_system !== 3 &&
                            <div className="mt-4">
                                <Button submit={true} func={addAddress} size={"md"} title={"Add address"}/>
                            </div>
                        }


                        {
                            +setting.default_delivery_system === 3 && showMap &&
                            <div className="col-span-12 block">
                                <div className="pb-1">
                                <span className="flex items-center gap-1 text-red-400 cursor-pointer"
                                      onClick={() => setShowMap(false)}><GoArrowLeft size="21"/>Previous</span>
                                </div>
                                <GoogleMap position={position} setPosition={setPosition}/>

                                <div className="mt-4">
                                    <Button submit={true} func={addAddress} size={"md"} title={"Add address"}/>
                                </div>
                            </div>
                        }
                    </div>

                </div>

            </Rodal>
        </div>
    );
};

export default AddressSave;